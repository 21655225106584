import axios from "axios";
import { store } from "@/store";

const httpClient = axios.create({
    baseURL: getUrl(),
    headers: {
        "Content-Type": "application/json",
    },
});

function getUrl(): string {
    console.log("mounted getUrl");
    if ((process.env.VUE_APP_MODE || "").indexOf("Production") >= 0 || (process.env.VUE_APP_MODE || "").indexOf("Staging") >= 0) {
        let host = window.location.host;
        host = host.replace("app", "api");
        console.log("apiURL getUrl", window.location.protocol + "//" + host + "/");
        return window.location.protocol + "//" + host + "/";
    } else {
        console.log("apiUR getUrlL", process.env.VUE_APP_API_URL);
        return process.env.VUE_APP_API_URL;
    }
}

httpClient.interceptors.request.use((p) => {
    try {
        if (store.getters.getLoginData && store.getters.getLoginData.idToken) {
            const idToken = store.getters.getLoginData.idToken;
            if (idToken && p.headers) {
                p.headers["Authorization"] = `Bearer  ${idToken}`;
            }
        }
    } catch (error) {
        console.log("internal http error", error);
    }
    return p;
});

export default httpClient;
