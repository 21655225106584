export const cities = [
    "Ankara",
    "İstanbul",
    "Adana",
    "Adıyaman",
    "Afyonkarahisar",
    "Ağrı",
    "Amasya",
    "Antalya",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkâri",
    "Hatay",
    "Isparta",
    "Mersin",
    "İzmir",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Kahramanmaraş",
    "Mardin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Rize",
    "Sakarya",
    "Samsun",
    "Siirt",
    "Sinop",
    "Sivas",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Şanlıurfa",
    "Uşak",
    "Van",
    "Yozgat",
    "Zonguldak",
    "Aksaray",
    "Bayburt",
    "Karaman",
    "Kırıkkale",
    "Batman",
    "Şırnak",
    "Bartın",
    "Ardahan",
    "Iğdır",
    "Yalova",
    "Karabük",
    "Kilis",
    "Osmaniye",
    "Düzce",
];

export const countries = [
    "Türkiye",
    "ABD Virgin Adaları",
    "Afganistan",
    "Aland Adaları",
    "Almanya",
    "Amerika Birleşik Devletleri",
    "Amerika Birleşik Devletleri Küçük Dış Adaları",
    "Amerikan Samoası",
    "Andora",
    "Angola",
    "Anguilla",
    "Antarktika",
    "Antigua ve Barbuda",
    "Arjantin",
    "Arnavutluk",
    "Aruba",
    "Avrupa Birliği",
    "Avustralya",
    "Avusturya",
    "Azerbaycan",
    "Bahamalar",
    "Bahreyn",
    "Bangladeş",
    "Barbados",
    "Batı Sahara",
    "Belize",
    "Belçika",
    "Benin",
    "Bermuda",
    "Beyaz Rusya",
    "Bhutan",
    "Bilinmeyen veya Geçersiz Bölge",
    "Birleşik Arap Emirlikleri",
    "Birleşik Krallık",
    "Bolivya",
    "Bosna Hersek",
    "Botsvana",
    "Bouvet Adası",
    "Brezilya",
    "Brunei",
    "Bulgaristan",
    "Burkina Faso",
    "Burundi",
    "Cape Verde",
    "Cebelitarık",
    "Cezayir",
    "Christmas Adası",
    "Cibuti",
    "Cocos Adaları",
    "Cook Adaları",
    "Çad",
    "Çek Cumhuriyeti",
    "Çin",
    "Danimarka",
    "Dominik",
    "Dominik Cumhuriyeti",
    "Doğu Timor",
    "Ekvator",
    "Ekvator Ginesi",
    "El Salvador",
    "Endonezya",
    "Eritre",
    "Ermenistan",
    "Estonya",
    "Etiyopya",
    "Falkland Adaları (Malvinalar)",
    "Faroe Adaları",
    "Fas",
    "Fiji",
    "Fildişi Sahilleri",
    "Filipinler",
    "Filistin Bölgesi",
    "Finlandiya",
    "Fransa",
    "Fransız Guyanası",
    "Fransız Güney Bölgeleri",
    "Fransız Polinezyası",
    "Gabon",
    "Gambia",
    "Gana",
    "Gine",
    "Gine-Bissau",
    "Granada",
    "Grönland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guyana",
    "Güney Afrika",
    "Güney Georgia ve Güney Sandwich Adaları",
    "Güney Kore",
    "Güney Kıbrıs Rum Kesimi",
    "Gürcistan",
    "Haiti",
    "Heard Adası ve McDonald Adaları",
    "Hindistan",
    "Hint Okyanusu İngiliz Bölgesi",
    "Hollanda",
    "Hollanda Antilleri",
    "Honduras",
    "Hong Kong SAR - Çin",
    "Hırvatistan",
    "Irak",
    "İngiliz Virgin Adaları",
    "İran",
    "İrlanda",
    "İspanya",
    "İsrail",
    "İsveç",
    "İsviçre",
    "İtalya",
    "İzlanda",
    "Jamaika",
    "Japonya",
    "Jersey",
    "Kamboçya",
    "Kamerun",
    "Kanada",
    "Karadağ",
    "Katar",
    "Kayman Adaları",
    "Kazakistan",
    "Kenya",
    "Kiribati",
    "Kolombiya",
    "Komorlar",
    "Kongo",
    "Kongo Demokratik Cumhuriyeti",
    "Kosta Rika",
    "Kuveyt",
    "Kuzey Kore",
    "Kuzey Mariana Adaları",
    "Küba",
    "Kırgızistan",
    "Laos",
    "Lesotho",
    "Letonya",
    "Liberya",
    "Libya",
    "Liechtenstein",
    "Litvanya",
    "Lübnan",
    "Lüksemburg",
    "Macaristan",
    "Madagaskar",
    "Makao S.A.R. Çin",
    "Makedonya",
    "Malavi",
    "Maldivler",
    "Malezya",
    "Mali",
    "Malta",
    "Man Adası",
    "Marshall Adaları",
    "Martinik",
    "Mauritius",
    "Mayotte",
    "Meksika",
    "Mikronezya Federal Eyaletleri",
    "Moldovya Cumhuriyeti",
    "Monako",
    "Montserrat",
    "Moritanya",
    "Mozambik",
    "Moğolistan",
    "Myanmar",
    "Mısır",
    "Namibya",
    "Nauru",
    "Nepal",
    "Nijer",
    "Nijerya",
    "Nikaragua",
    "Niue",
    "Norfolk Adası",
    "Norveç",
    "Orta Afrika Cumhuriyeti",
    "Özbekistan",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua Yeni Gine",
    "Paraguay",
    "Peru",
    "Pitcairn",
    "Polonya",
    "Portekiz",
    "Porto Riko",
    "Reunion",
    "Romanya",
    "Ruanda",
    "Rusya Federasyonu",
    "Saint Helena",
    "Saint Kitts ve Nevis",
    "Saint Lucia",
    "Saint Pierre ve Miquelon",
    "Saint Vincent ve Grenadinler",
    "Samoa",
    "San Marino",
    "Sao Tome ve Principe",
    "Senegal",
    "Seyşeller",
    "Sierra Leone",
    "Singapur",
    "Slovakya",
    "Slovenya",
    "Solomon Adaları",
    "Somali",
    "Sri Lanka",
    "Sudan",
    "Surinam",
    "Suriye",
    "Suudi Arabistan",
    "Svalbard ve Jan Mayen",
    "Svaziland",
    "Sırbistan",
    "Sırbistan-Karadağ",
    "Şili",
    "Tacikistan",
    "Tanzanya",
    "Tayland",
    "Tayvan",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad ve Tobago",
    "Tunus",
    "Turks ve Caicos Adaları",
    "Tuvalu",
    "Türkmenistan",
    "Uganda",
    "Ukrayna",
    "Umman",
    "Uruguay",
    "Uzak Okyanusya",
    "Ürdün",
    "Vanuatu",
    "Vatikan",
    "Venezuela",
    "Vietnam",
    "Wallis ve Futuna",
    "Yemen",
    "Yeni Kaledonya",
    "Yeni Zelanda",
    "Yunanistan",
    "Zambiya",
    "Zimbabve",
];
