export enum MutationTypes {
  ADD_NOTIFICATION = "ADD_NOTIFICATION",
  CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION",
  SET_LOGINDATA = "SET_LOGINDATA",
  SET_LOGINDATA_EMAILVERIFIED = "SET_LOGINDATA_EMAILVERIFIED",
  SET_LOGINDATA_DISPLAYNAME = "SET_LOGINDATA_DISPLAYNAME",
  SET_LOGINDATA_ORGANIZATIONDISPLAYNAME = "SET_LOGINDATA_ORGANIZATIONDISPLAYNAME",
  SET_EDITTING_FLOW_ID = "SET_EDITTING_FLOW_ID",
  SET_DEPARTMENTID_FOR_CREATE_FLOW = "SET_DEPARTMENTID_FOR_CREATE_FLOW",
  SET_SIGNATURE_TYPE_FOR_CREATE_FLOW = "SET_SIGNATURE_TYPE_FOR_CREATE_FLOW",
  LOGOFF = "LOGOFF",
  SET_HUBSTATE = "SET_HUBSTATE",
  SET_HUBCONNECTION = "SET_HUBCONNECTION",
  SET_LOCALE = "SET_LOCALE",
  SET_LASTSEARCH = "SET_LASTSEARCH",
  SET_SIGNPORTALDATA = "SET_SIGNPORTALDATA",
  SET_APIURL = "SET_APIURL",
  LOG_OFF = "LOG_OFF",
}
