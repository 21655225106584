import { FlowStatusEnum } from "./SharedTypes";

export default {
  rgbToYIQ(r: number[]) {
    return (r[0] * 299 + r[1] * 587 + r[2] * 114) / 1000;
  },

  hexToRgb(hex: string) {
    if (!hex || hex === undefined || hex === "") {
      return undefined;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : undefined;
  },
  contrast(colorHex: string, threshold = 128) {
    if (colorHex === undefined) {
      return "#000";
    }

    const rgb = this.hexToRgb(colorHex);

    if (rgb === undefined) {
      return "#000";
    }
    const result = this.rgbToYIQ(rgb) >= threshold ? "#000" : "#fff";

    return result;
  },

  getColorForFlowStatus(flowStatusEnum: FlowStatusEnum) {
    if (flowStatusEnum === FlowStatusEnum.Created) return "bg-gray-300 text-gray-800";
    else if (flowStatusEnum === FlowStatusEnum.Draft) return "bg-gray-300 text-gray-800";
    else if (flowStatusEnum === FlowStatusEnum.Running) return "bg-green-300 text-gray-800";
    else if (flowStatusEnum === FlowStatusEnum.Finished) return "bg-yellow-300 text-gray-900";
    else if (flowStatusEnum === FlowStatusEnum.Cancelled) return "bg-red-300 text-gray-800";
  },
};
