import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import { LanguageItem } from "./types/LanguageItem";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

const messages = loadLocaleMessages();
export const languages = Object.getOwnPropertyNames(loadLocaleMessages());
export const selectedLocale = checkDefaultLanguage(messages) || process.env.VUE_APP_I18N_LOCALE || "tr";
export const languageItems = [
    { key: "en", name: "English" },
    { key: "tr", name: "Türkçe" },
] as Array<LanguageItem>;

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages<VueMessageType> = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key).default;
        }
    });
    return messages;
}

function checkDefaultLanguage(messages: LocaleMessages<VueMessageType>) {
    let matched = null as null | string;
    const languages = Object.getOwnPropertyNames(messages);
    languages.forEach((lang) => {
        if (lang === navigator.language) {
            matched = lang;
        }
    });
    if (!matched) {
        languages.forEach((lang) => {
            const languagePartials = navigator.language.split("-")[0];
            if (lang === languagePartials) {
                matched = lang;
            }
        });
    }
    if (!matched) {
        languages.forEach((lang) => {
            const languagePartials = navigator.language.split("-")[0];
            if (lang.split("-")[0] === languagePartials) {
                matched = lang;
            }
        });
    }
    return matched;
}

const datetimeFormats = {
    en: {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        },
    },
    tr: {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        },
    },
};

export default createI18n({
    legacy: false,
    locale: selectedLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "tr",
    messages: messages,
    globalInjection: true,
    datetimeFormats,
});
