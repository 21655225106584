import { NotificationItem } from "@/types/NotificationItem";
import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State } from "./state";
import { nanoid } from "nanoid";
import {
  GetFlowsRequest_SearchParameters,
  SignatureTypeEnum,
  SignInUpResult,
  SignPortalData,
} from "@/types/SharedTypes";
import { HubConnectionState, HubConnection } from "@microsoft/signalr";
import { LanguageItem } from "@/types/LanguageItem";

export type Mutations<S = State> = {
  [MutationTypes.ADD_NOTIFICATION](
    state: S,
    notificationItem: NotificationItem
  ): void;
  [MutationTypes.CLOSE_NOTIFICATION](
    state: S,
    notificationItemId: string
  ): void;
  [MutationTypes.SET_LOGINDATA](state: S, signInUpResult: SignInUpResult): void;
  [MutationTypes.SET_LOGINDATA_DISPLAYNAME](
    state: S,
    displayName: string
  ): void;
  [MutationTypes.SET_LOGINDATA_EMAILVERIFIED](
    state: S,
    newValue: boolean
  ): void;
  [MutationTypes.SET_LOGINDATA_ORGANIZATIONDISPLAYNAME](
    state: S,
    organizationDisplayName: string
  ): void;
  [MutationTypes.SET_EDITTING_FLOW_ID](state: S, payload: number | null): void;
  [MutationTypes.SET_DEPARTMENTID_FOR_CREATE_FLOW](
    state: S,
    payload: number | null
  ): void;
  [MutationTypes.SET_SIGNATURE_TYPE_FOR_CREATE_FLOW](
    state: S,
    payload: SignatureTypeEnum | null
  ): void;
  [MutationTypes.LOGOFF](state: S): void;
  [MutationTypes.SET_HUBSTATE](
    state: S,
    payload: HubConnectionState | undefined
  ): void;
  [MutationTypes.SET_HUBCONNECTION](
    state: S,
    payload: HubConnection | undefined
  ): void;
  [MutationTypes.SET_LOCALE](state: S, payload: LanguageItem): void;
  [MutationTypes.SET_LASTSEARCH](
    state: S,
    payload: GetFlowsRequest_SearchParameters
  ): void;
  [MutationTypes.SET_SIGNPORTALDATA](
    state: S,
    payload: SignPortalData | null
  ): void;
  [MutationTypes.SET_APIURL](state: S, payload: string): void;
  [MutationTypes.LOG_OFF](state: S, payload: undefined): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ADD_NOTIFICATION](state, notificationItem: NotificationItem) {
    notificationItem.id = nanoid();
    state.notifications.push(notificationItem);
    setTimeout(() => {
      state.notifications.splice(
        state.notifications.findIndex((n) => n.id === notificationItem.id),
        1
      );
    }, 4000);
  },
  [MutationTypes.CLOSE_NOTIFICATION](state, notificationItemId: string) {
    const index = state.notifications.findIndex(
      (n) => n.id === notificationItemId
    );
    if (index >= 0) state.notifications.splice(index, 1);
  },
  [MutationTypes.SET_LOGINDATA](state, signInUpResult: SignInUpResult) {
    state.signInUpResult = signInUpResult;
  },
  [MutationTypes.SET_LOGINDATA_EMAILVERIFIED](state, newValue: boolean) {
    if (state.signInUpResult) state.signInUpResult.eMailVerified = newValue;
  },
  [MutationTypes.SET_LOGINDATA_DISPLAYNAME](state, displayName: string) {
    if (state.signInUpResult) state.signInUpResult.displayName = displayName;
  },
  [MutationTypes.SET_LOGINDATA_ORGANIZATIONDISPLAYNAME](
    state,
    organizationDisplayName: string
  ) {
    if (state.signInUpResult)
      state.signInUpResult.organizationDisplayName = organizationDisplayName;
  },
  [MutationTypes.SET_EDITTING_FLOW_ID](state, payload: number | null) {
    state.edittingFlowId = payload;
  },
  [MutationTypes.SET_DEPARTMENTID_FOR_CREATE_FLOW](
    state,
    payload: number | null
  ) {
    state.departmentIdForCreateFlow = payload;
  },
  [MutationTypes.SET_SIGNATURE_TYPE_FOR_CREATE_FLOW](
    state,
    payload: SignatureTypeEnum | null
  ) {
    state.signatureTypeForCreateFlow = payload;
  },
  [MutationTypes.LOGOFF](state) {
    state.edittingFlowId = null;
    state.signInUpResult = null;
  },
  [MutationTypes.SET_HUBSTATE](state, payload: HubConnectionState | undefined) {
    state.HubState = payload;
  },
  [MutationTypes.SET_HUBCONNECTION](state, payload: HubConnection | undefined) {
    state.HubConnection = payload;
  },
  [MutationTypes.SET_LOCALE](state, newLocale: LanguageItem) {
    state.locale = newLocale;
  },
  [MutationTypes.SET_LASTSEARCH](
    state,
    newLocale: GetFlowsRequest_SearchParameters
  ) {
    state.lastSearchParameters = newLocale;
  },
  [MutationTypes.SET_SIGNPORTALDATA](state, payload: SignPortalData | null) {
    //
  },
  [MutationTypes.SET_APIURL](state, payload: string) {
    state.apiUrl = payload;
  },
  [MutationTypes.LOG_OFF](state, payload: undefined) {
    state.edittingFlowId = null;
    state.signInUpResult = null;
  },
};
