import i18n from "@/i18n";
import axios, { AxiosError } from "axios";
import router from ".././router";
export default {
    getErrorMessage(error: Error) {
        if (axios.isAxiosError(error)) {
            if (error.response !== undefined && error.response !== null) {
                if (error.response.status === 401) {
                    router.push("/login");
                    return "Yetkisiz erişim. Giriş sayfasına yönlendirileceksiniz.";
                } else if (error.response.status === 500) {
                    return "Sunucuda hata oluştu.";
                } else if (error.response.status === 404) {
                    return "Servis bulunamadı.";
                } else if (error.response.status === 501) {
                    return "Bilinmeyen fonksiyon.";
                } else {
                    const aError = error as AxiosError;
                    if (aError.code) {
                        return i18n.global.t(aError.code).toString() || "";
                    } else {
                        return error.message;
                    }
                }
            } else if (error.request !== undefined && error.request !== null) {
                return "Sunuculara erişilemedi.";
            } else {
                if (error.message !== undefined && error.message !== null) {
                    return error.message;
                } else {
                    return error.name;
                }
            }
        } else {
            return error.message;
        }
    },
};
