export function formatSize(size: number | undefined) {
  if (size === undefined) return "";
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(0) + " MB";
  } else if (size > 1024) {
    return (size / 1024).toFixed(0) + " KB";
  }
  return size.toString() + " B";
}


export function formatDecimal(amount: number, fractionDigits = 2) {
  const formatter = new Intl.NumberFormat("tr-TR", {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });

  return formatter.format(amount); /* $2,500.00 */
}

export function formatDateWithMonthName(inputDate: string): string {
  const options = { year: "numeric", month: "long", day: "2-digit" } as Intl.DateTimeFormatOptions;
  const today = new Date(inputDate);
  return today.toLocaleDateString("en-US", options);
}
