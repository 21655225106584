import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "@/assets/styles/tailwind.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import CButton from "@/components/Common/CButton.vue";
import CRouterLink from "@/components/Common/CRouterLink.vue";
import TreeItem from "@/components/Common/TreeItem.vue";
import { QuillEditor } from "@vueup/vue-quill";
import i18n from "./i18n";

const app = createApp(App).use(i18n);
app.component("CButton", CButton);
app.component("CRouterLink", CRouterLink);
app.component("TreeItem", TreeItem);
app.component("QuillEditor", QuillEditor);

app.use(store).use(router).mount("#app");
